import Body from "./Component/Body";

function App() {
  return (
    <div className="">
        <div className="App">
          <Body />
        </div>
    </div>
  );
}

export default App;
